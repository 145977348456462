import { createStore } from 'vuex'

export default createStore({
  state: {
    Churches:[],
    Users:[],
    Logon:{},
    authenticated:false,
    usr_lvl:""
  },
  mutations: {
    SET_CHURCHES(state, payload){
      state.Churches = payload;
    },
    SET_USERS(state,payload){
      state.Users = payload;
    },
    SET_USERLOGON(state,payload){
      state.Logon = payload;
    },
    SET_AUTHENTICATION(state,payload){
      state.authenticated = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
