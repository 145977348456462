<template>
   <div class="container">
       <div class="card login-card">
           <div class="row no-gutters">
               <div class="col-md-12">
                   <div class="card-body">
                    <h4>Reset Password</h4>
                        <div class="mt-3 form-group">
                   <div class="form-floating mb-4">
                     <input v-model="NewPassword" type="password" class="form-control" id="floatingNewPassword" placeholder="New Password">
                     <label for="floatingInput">New Password</label>
                   </div>
                   <div class="form-group mb-3">
                      <div class="form-floating">                       
                        <input v-model="ConfirmPassword" type="password" class="form-control" id="floatingConfirmPassword" placeholder="Confirm Password" v-on:keyup.enter="onSubmit">
                        <label for="floatingPassword">Confirm Password</label>
                      </div>
                   </div>
                   <div class="row d-flex flex-row-reverse">
                        <button type="submit" class="btn btn-success mb-2" v-on:click="onSubmit">Change Password</button>
                   </div>
                    </div>
                   </div>
               </div>
           </div>
       </div>
   </div> 
</template>
<script>
export default {
    name:"ResetPassword",
    data(){
        return{
            NewPassword:"",
            ConfirmPassword:""
        }
    },
    methods:{
        onSubmit:function(){
            if(this.NewPassword == this.ConfirmPassword)
            {
               this.$emit('newPassword',this.NewPassword);
            }
            else{
                  Swal.fire({
                    title:"Passwords don't match.",
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    });
            }
        }
    }   
}
</script>
<style>
    .no-gutters {
    margin-right: 0;
    margin-left: 0;
}
  .login-card{
    border: 0;
    border-radius: 27.5px;
    box-shadow: 0 10px 30px 0 rgb(172 168 168 / 43%);
    overflow: hidden;
  }
</style>