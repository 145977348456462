<template>
    <div class="container">
      <div class="card login-card">
        <div class="row no-gutters">
           <div class="col-md-12">
              <div class="card-body">
                <h2 class="text-center">Forgot Password?</h2>
                <br/>
                <h5 class="text-center">Please enter your email address. You will receive a code to create a new password.</h5>
               <br>          
                <div class="form-group">
                   <div class="form-floating mb-4">
                     <input v-model="email" type="text" class="form-control" id="floatingInput" placeholder="Enter email address">
                     <label for="floatingInput">Email Address</label>
                   </div>
                    </div>
                    <div class="d-flex flex-row-reverse">
                        <button type="submit" class="btn btn-success btn-login mb-2" v-on:click="sendLink">Send Link</button>
                    </div>
              </div>   
           </div>
             </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name:"ForgotPassword",
    data(){
        return{
            email:""
        }
    },
    methods:{
      sendLink:function(){
          var obj ={
              email : this.email
          }
          axios.post("https://api.jilcwseeo.org/api/User/send-link",obj)
          .then((response)=>{
               this.$emit('info',{code:String(response.data),emailemit:this.email});
          })
          .catch((error)=>{
            if(error.response.status == 1)
            {
             Swal.fire({
                    title:'No account found with that email address.',
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    });
            }
            else{
                 Swal.fire({
                    title:'Something went wrong.',
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    });
            }
          })
      },
     
    }
}
</script>
<style scoped>
.container{
      width: 50%;
}
  .login-card{
    border: 0;
    border-radius: 27.5px;
    box-shadow: 0 10px 30px 0 rgb(172 168 168 / 43%);
    overflow: hidden;
  }
    .no-gutters {
    margin-right: 0;
    margin-left: 0;
}
</style>