<template>
  <div class="main-container">
    <router-view/>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'App',
  data(){
    return{
     
    }
  },
  created(){
   
  },
  methods:{
    onLogout(){
       this.$router.push("/");
    },
  }
}
</script>
<style>
  .text-right{
    text-align: right;
  }
  label{
    font-weight: bold;
  }
  .font-weight-bold{
    font-weight: bold;
  }
 .main-container{
    height: 100%;
    width:100%;
  }
  .item-center-col{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .item-center-row{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-space-row{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .ml-4{
   margin-left: 1rem !important;
  }
 .mr-4{
   margin-right: 1rem !important;
  }
  .text-size-12{
    font-size: 20px;
  }
  
</style>
