<template>
    <div class="container">
        <div class="card login-card">
            <div class="row no-gutters">
                <div class="col-md-12">
                      <div class="card-body">
                        <div class="row">
                            <h3 class="text-center">Enter the code we've sent in your email
                                <br> 
                                <br>   
                                <b class="h4 font-weight-bold">{{email}}</b>
                            </h3>
                        </div>
        <div class="mt-3 row d-flex justify-content-center">
        <div class="col-md-2" v-bind:key="data.id" v-for="(data,index) in codes">
            <input class="form-control"  v-bind:ref="refsPush" v-model="data.num" type="number"  v-on:input="moveToNextField($event.target.value, index)" maxlength="1">
        </div>
        </div>
        <div class="row mt-3">
             <button class="btn btn-link">Resend Code</button>
        </div>
         <div class="mt-3 row d-flex justify-content-center">
             <div class="col-md-4 d-flex justify-content-center">
                  <button class="btn btn-primary" v-on:click="Check" ref="verify">Verify Code</button>
             </div>        
         </div>
        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"ForgotCode",
    props:{
        codeForgot:String,
        email:String
    },
    data(){
        return{
            codeData:0,
            emailData:"",
             codes:[
                 {
                     id:1,
                     num:""
                 },
                 {
                     id:2,
                     num:""
                 },
                 {
                     id:3,
                     num:""
                 },
                 {
                     id:4,
                     num:""
                 }
             ],
             itemRefs:[]
           
        }
    },
    mounted(){
        this.itemRefs[0].focus();
    },
    created(){
        this.codeData = this.codeForgot;
        this.emailData = this.email;
        this.itemRefs = [];
    },
    beforeUpdate(){
         this.itemRefs = [];
    },
    methods:{
       Check:function(){
           var code = "";
           for(let i=0;i<=this.codes.length-1;i++)
           {
               code = code + this.codes[i].num;
           }
           code = String(code);
           if(String(code) == String(this.codeData)){
                this.$emit('verified');
           }
           else{
               alert("Wrong Code");
           }
       },
       ResendCode:function(){
           var obj ={
              email : this.emailData
          }
          axios.post("https://api.jilcwseeo.org/api/User/send-link",obj)
          .then((response)=>{
             this.codeData = response.data;
             this.itemRefs = [];
          })
          .catch((error)=>{
             Swal.fire({
                    title:'Server Error.',
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    })
          })
       },
       moveToNextField:function(value, index) {  
           var nextIndex = index + 1;
           if((nextIndex <this.itemRefs.length) && value.length == 1)
           {
               this.itemRefs[nextIndex].focus();
           }
           else if((nextIndex < this.itemRefs.length) && value.length == 2){
               this.itemRefs[nextIndex].focus();
               this.codes[index].num = value.substring(1);
           }
           else
           {
              this.codes[index].num = ""; 
           }

           if((nextIndex == this.itemRefs.length)&& value.length == 1){
             this.codes[index].num = value;
             this.$refs.verify.focus();
           }
           else if((nextIndex == this.itemRefs.length)&& value.length == 2){
             this.codes[index].num = value.substring(1);
             this.$refs.verify.focus();
           }
       },
       refsPush(el){
           if(el){
               this.itemRefs.push(el);
           }
       }
    }
}
</script>
<style scoped>
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
}
  .login-card{
    border: 0;
    border-radius: 27.5px;
    box-shadow: 0 10px 30px 0 rgb(172 168 168 / 43%);
    overflow: hidden;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
}
</style>