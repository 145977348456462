import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    redirect: {
      name: "Login"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
              window.location.href = 'login';
            }
          })
      }
      else {
        next();
      }
    }
  },
  {
    path: '/mfr',
    name: 'MFR',
    component: () => import('../views/MFR.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
              window.location.href = 'login';
            }
          })
      }
      else {
        next();
      }
    }
  },
  {
    path: '/msr',
    name: 'MSR',
    component: () => import('../views/MSR.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
              window.location.href = 'login';
            }
          })
      }
      else {
        next();
      }
    }
  },
  {
    path: '/pis',
    name: 'PIS',
    component: () => import('../views/PIS.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
              window.location.href = 'login';
            }
          })
      }
      else {
        next();
      }
    }
  },
  {
    path: '/church',
    name: 'Church',
    component: () => import('../views/Church.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("log");
      var actual_log = JSON.parse(atob(encoded));
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
            }
          })
      }
      else{
      if(actual_log.Role !="Church") next();
      else{
         Swal.fire({
        title: 'You dont have permission.',
        icon: "error",
        confirmButtonColor: '#0d6efd'
        });
        next(from);
      }
     }
    }
  },
  {
    path: '/users',
    name: 'User',
    component: () => import('../views/Users.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("log");
      var actual_log = JSON.parse(atob(encoded));
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
            }
          })
      }
      else{
      if(actual_log.Role !="Church") next();
      else{
         Swal.fire({
        title: 'You dont have permission.',
        icon: "error",
        confirmButtonColor: '#0d6efd'
        });
        next(from);
      }
     }

    }
  },
  {
    path: '/AccountSettings',
    name: 'AccountSettings',
    component: () => import('../views/AccountSettings.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
            }
          })
      }
      else {
        next();
      }
    }
  },
  {
    path: '/finance-report',
    name: 'FinanceReport',
    component: () => import('../views/MFR_Report.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("log");
      var actual_log = JSON.parse(atob(encoded));
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
            }
          })
      }
      else{
      if(actual_log.Role !="Church") next();
      else{
         Swal.fire({
        title: 'You dont have permission.',
        icon: "error",
        confirmButtonColor: '#0d6efd'
        });
        next(from);
      }
     }
      
    }
  },
  {
    path: '/Members',
    name: 'Members',
    component: () => import('../views/Members.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
            }
          })
      }
      else {
        next();
      }
    }
  },
  {
    path: '/TithersList',
    name: 'TithersList',
    component: () => import('../views/TithersList.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
            }
          })
      }
      else {
        next();
      }
    }
  },
  {
    path: '/tithersyear',
    name: 'TithersYear',
    component: () => import('../views/Tithers_Year.vue'),
    beforeEnter: (to, from, next) => {
      var encoded = localStorage.getItem("log");
      var actual_log = JSON.parse(atob(encoded));
      var encoded = localStorage.getItem("tok");
      var actual = JSON.parse(atob(encoded));
      if (actual == false) {
        Swal.fire({
          title: 'You need to login first.',
          icon: "error",
          confirmButtonColor: '#0d6efd'
        })
          .then((result) => {
            if (result.isConfirmed) {
              next('/login');
            }
          })
      }
      else{
      if(actual_log.Role !="Church") next();
      else{
         Swal.fire({
        title: 'You dont have permission.',
        icon: "error",
        confirmButtonColor: '#0d6efd'
        });
        next(from);
      }
     }
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
