<template>
 <Loader v-if="isLoading" />
  <div class="d-flex align-items-center min-vh-100 py-3 py-md-0" >
    <div class="container" v-if="isForgot==1">
       <div class="card login-card">
        <div class="row no-gutters">
           <div class="col-md-12">
              <div class="card-body">
                 <div class="brand-wrapper d-flex justify-content-center">
                  <img src="../assets/logoLog.png" alt="logo" class="logo">
                </div>
               <br>          
                <div class="form-group">
                   <div class="form-floating mb-4">
                     <input v-model="Username" type="text" class="form-control" id="floatingInput" placeholder="Username">
                     <label for="floatingInput">Username</label>
                   </div>
                   <div class="form-group mb-3">
                      <div class="form-floating">                       
                        <input v-model="Password" type="password" class="form-control" id="floatingPassword" placeholder="Password" v-on:keyup.enter="onSubmit">
                        <label for="floatingPassword">Password</label>
                      </div>
                   </div>
                    <button class="btn btn-link forgot-password-link" v-on:click="isForgot = 0;">Forgot password?</button>
                    </div>
                  <button type="submit" class="btn btn-primary btn-login mb-2" v-on:click="onSubmit">Login</button>
              </div>   
           </div>
        </div>
    </div>
    </div>
    <ForgotPassword v-if="isForgot==0" v-on:info="CodeSent"/>
    <ForgotCode v-if="isForgot==2" v-on:verified="isForgot=3" v-bind:code-forgot="code" v-bind:email="email"/>
    <ResetPassword v-if="isForgot==3" v-on:new-password="SetPassword"/>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import axios from 'axios';
import ForgotPassword from "../components/ForgotPassword.vue";
import ForgotCode from "../components/ForgotCode.vue";
import ResetPassword from "../components/ResetPassword.vue";

export default {
  name: 'Login',
  data(){
    return{
      Username:"",
      Password:"",
      isForgot:1,
      code:0,
      email:"",
       glob_Churches:[],
       isLoading: false,
      fullPage: true
    }
  },
  components: {
    Loader,
    ForgotPassword,
    ForgotCode,
    ResetPassword
  },
  methods:{
    onSubmit(){
      this.isLoading = true;
      var obj= {
        Username : this.Username,
        Password : this.Password
      }
      axios.post("https://api.jilcwseeo.org/api/User/get-user",obj)
      .then((response)=>{
        if(response.status==200)
        {
          var data = response.data[0];  
          if(data.role !="Gatekeeper")
          {       
          var d = new Date(data.birthdate);
         var date = new Date( d.getTime() - ( d.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
          var obj = {
            Id : data.id,
            FirstName : data.firstName,
            LastName :data.lastName,
            CreatedBy : data.createdBy,
            Username : data.username,
            Birthdate : date,
            Email : data.email,
            Role :data.role,
            ChurchId : data.churchId
          };
          this.GETALL();
          this.$store.commit("SET_USERLOGON",obj);
          var encoded = btoa(JSON.stringify(obj))
          localStorage.setItem('log',encoded);
          this.$store.commit("SET_AUTHENTICATION",true);
          var auth = btoa(true);
          localStorage.setItem("tok",auth);
          this.$router.push("/MFR");
          this.Username = "";
          this.Password ="";
          this.isLoading = false;   
          }
          else
          {
            Swal.fire({
                    title:'Wrong username or password.',
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    });
          }
        }    
      })
      .catch((error)=>{
        if(error.response)
        {
      this.isLoading = false;
           Swal.fire({
                    title:'Wrong username or password.',
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    });
        }
      })
    },
    CodeSent({code,emailemit}){
      this.code = code;
      this.email = emailemit;
      this.isForgot = 2;
    },
    SetPassword(value){
          var obj ={
              email : this.email,
              password : value
          };
          axios.post("https://api.jilcwseeo.org/api/User/reset-password",obj)
          .then((response)=>{
            Swal.fire({
                    title:'Password has been reset',
                    icon:"success",
                    confirmButtonColor: '#0d6efd'
                    })
            this.isForgot = 1;
            this.code = 0;
          })
          .catch((error)=>{
               Swal.fire({
                    title:'Something went wrong. \n Please try again.',
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    })
          })
    },
    GETALL(){
          this.glob_Churches = [];
          axios.get("https://api.jilcwseeo.org/api/Church/getChurches")
          .then((response)=>{
              let obj = response.data;
              if(obj.length > 0)
              {
                  for(let i=0;i<=obj.length-1;i++)
                  {
                          let obj_data= obj[i];
                          var church_obj = {
                              ChurchName : obj_data.churchName,
                              ChurchPastor:obj_data.churchPastor,
                              Provincial: obj_data.provincial,
                              Operation : obj_data.operation,
                              District: obj_data.district,
                              Id:obj_data.id,
                              ChurchId:obj_data.churchId
                          }
                           this.glob_Churches.push(church_obj);
                      }
                    this.$store.commit("SET_CHURCHES",this.glob_Churches);
                    var encoded = btoa(JSON.stringify(this.glob_Churches))                   
                    localStorage.setItem('ch',encoded);
              }
          })
          .catch((error)=>{
             Swal.fire({
                    title:"Can't connect to the server.",
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    })
          });
          let glob_users = [];
           axios.get("https://api.jilcwseeo.org/api/User/get-users")
            .then((response)=>{
                let obj = response.data;
                obj.forEach(element => {
                    var user_obj = {
                      Id : element.Id,
                      FirstName : element.firstName,
                      LastName :element.lastName,
                      Birthdate : element.birthdate,
                      Username :element.username,
                      CreatedBy : element.createdBy,
                      Role : element.role
                    }
                    glob_users.push(user_obj);
                });
                 this.$store.commit("SET_USERS", glob_users);
             })
            .catch((error)=>{
               Swal.fire({
                    title:"Can't connect to the server.",
                    icon:"error",
                    confirmButtonColor: '#0d6efd'
                    })
            })


    },
  }
}
</script>
<style scoped>
.container{
      width: 50%;
}
.login-card .forgot-password-link {
    font-size: 14px;
    color: #919aa3;
    margin-bottom: 12px;
}
a  {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
  .brand-wrapper .logo {
    height: 40px;
  }
  .btn-login{
    margin-top:2%;
    widows: 100%;
     font-size: 18px;
  }
  .login-card{
    border: 0;
    border-radius: 27.5px;
    box-shadow: 0 10px 30px 0 rgb(172 168 168 / 43%);
    overflow: hidden;
  }
  .login-card-img {
    border-radius: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
}
  .log-textfield{
    margin-top:1%;
    height: 100%;
    width:50%;
    font-size: 18px;
    text-align: center;
  }

  .brand-wrapper .logo{
      height: 100%;
    width: 65%;
    object-fit: contain;
}



@media screen and (max-width: 992px) {
  .container {
    width: 100% !important;
  }
   .brand-wrapper .logo{
      height: 100%;
    width: 80%;
    object-fit: contain;
}
}

</style>
